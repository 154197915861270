@font-face {
  font-family: 'apercu-medium';
  src: url('/fonts/apercu-medium-pro.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'apercu-regular';
  src: url('/fonts/apercu-regular-pro.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'apercu-bold';
  src: url('/fonts/apercu-bold-pro.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
